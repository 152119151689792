<template>
  <v-container id="operating-status" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                東側捲網
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-01.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                西側上層
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-01.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                西側下層
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-01.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                西側捲網
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-01.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                北側捲揚
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-01.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                風扇 1
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-02.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                風扇 2
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-02.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                冰霧主機
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-03.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                噴霧
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-04.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                蜂鳴器東
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-05.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                蜂鳴器
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-05.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                風扇 3
              </v-list-item-title>
              <v-list-item-subtitle>暫存器：32 紀錄中</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar rounded size="50" color="grey">
              <img src="@/assets/status-02.jpg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="start">
      <v-col cols="12">
        <div class="mb-5">
          <h1>Free API 取回資料</h1>
          <p>https://catfact.ninja/fact</p>
        </div>
        <div class="mb-5">
          <h3>所有資料</h3>
          {{ capiData }}
        </div>
        <div class="mb-5">
          <h3>分層資料</h3>
          data / fact: {{ capiFact }}
        </div>
      </v-col>
    </v-row>

    <!-- <hr>

    <div
      id="animated-number-demo"
      class="mt-5"
    >
      <input
        v-model="value"
        type="number"
        class=""
        step="20"
      >
      <h1>{{ displayValue }}</h1>
    </div> -->

    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
// import gsap from 'gsap'
export default {
  name: 'OperatingState',
  data () {
    const capiData = '讀取中……'
    const capiFact = '讀取中……'
    return {
      capiData: capiData,
      capiFact: capiFact
      // value: 10,
      // displayValue: 10,
    }
  },
  // watch: {
  //   value () {
  //     gsap.to(this, {
  //       tweenValue: this.value,
  //       onUpdate: () => {
  //         this.displayValue = Math.ceil(this.tweenValue)
  //       },
  //     })
  //   },
  // },
  created () {
    this.$http.get('https://catfact.ninja/fact').then(response => {
      this.capiData = response
      this.capiFact = response.data.fact
    })
  }
}
</script>

<style scoped lang="scss">
#myMap {
  display: none;
}
.v-input__slot > .v-label {
  -webkit-order: 1;
  order: 1;
  font-weight: 800;
}
.v-input__slot > .v-input--selection-controls__input {
  -webkit-order: 2;
  order: 2;
}
.w-100 {
  width: 100% !important;
}
.v-messages {
  margin-bottom: 1em;
}
</style>
